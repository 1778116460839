.loader {
  display: flex;
  justify-content: center;
  margin-top: 17%;
}

/* .dark {
  color: #dddddd;
  background-color: #0d0b1a;
  border: 1px solid lightgray;
  border-radius: 5px;
}

.client-form__values {
  color: white;
  background-color: #777777;
}

.client-form__values:focus {
  color: white;
}

.client-form__values::placeholder {
  color: rgba(255, 255, 255, 0.63);
}

#country-select {
  background-color: #777777;
}

#country-select a {
  color: white;
}

#country-select a:hover {
  color: black;
} */
