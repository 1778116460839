tr:nth-child(even) {
  background-color: lightgray;
}

tr {
  height: 2vw;
  overflow: hidden;
  white-space: nowrap;
}

th > div > span {
  margin-left: 5px;
}

td {
  vertical-align: middle;
}
