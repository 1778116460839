.project-modal {
  max-width: 70vw !important;
}

.react-datepicker__triangle {
  transform: translate(25px, 0) !important;
}

.react-datepicker__input-container > input {
  border: 1px solid lightgray;
  border-radius: 5px;
  height: 38px;
  width: 100%;
  padding: 6px 12px;
}

.react-datepicker-ignore-onclickoutside input {
  border-radius: 5px;
}
