.settings-form {
  border: 1px solid lightgray;
  border-radius: 5px;
  width: 450px;
  height: 450px;
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
}
